export default Object.freeze({
    EMAIL_CONFIRMED: "Your email has been confirmed ! Thank you.",
    CHECK_CONFIRMATION_EMAIL :"Please check your emails and follow the instructions. Check your spam emails if you can't find it. ",
    LOGGED_IN :"You are logged in. ",
    LOGGED_OUT :"You are logged out. ",
    ERROR :"Something went wrong. ",
    SAVED :"Saved successfully. ",
    CONFIRMED :"Booking confirmed. ",
    CANCELLED :"Booking cancelled. ",
    PAYMENT :"The payment has been approved ",
    PAYMENT_FAILED :"The payment failed ",
    SENT :"The message has been sent successfully. ",
    DELETED :"Deleted successfully. ",
})

