import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import './assets/css/style.css';
import './assets/css/materialdesignicons.min.css';
import axios from "axios";
import { mapMutations } from "vuex";

/*const Privacy = */() => import("./assets/home_1280.webp");
() => import("./assets/course_250.webp");
() => import("./assets/sharing_250.webp");
() => import("./assets/cocktail_250.webp");
() => import("./assets/grazing_250.webp");
() => import("./assets/icons/groceries.webp");
() => import("./assets/icons/cook.webp");
() => import("./assets/icons/serve.webp");
() => import("./assets/icons/clean.webp");

import VideoBackground from 'vue-responsive-video-background-player'
Vue.component('video-background', VideoBackground);
// import { Plugin } from 'vue-responsive-video-background-player'
// Vue.use(Plugin);

let VueGtag;
new Promise((resolve) => {
    const VueGtagImport = import('vue-gtag');
    VueGtagImport.then(obj=>{
        VueGtag=obj;
        resolve();
    });
    }).then(()=>{
    Vue.use(VueGtag, {
    appName: 'Kootto-webapp-uat',
    pageTrackerEnabled: true,
    pageTrackerScreenviewEnabled: true,
    config: {
        id: process.env.VUE_APP_ANALYTICS_KEY
    }
})
})

// import VueGtag from "vue-gtag"
// // console.log("Analytics" + process.env.VUE_APP_ANALYTICS_KEY);
// Vue.use(VueGtag, {
//     appName: 'Kootto-webapp-uat',
//     pageTrackerScreenviewEnabled: true,
//     config: {
//         id: process.env.VUE_APP_ANALYTICS_KEY
//     }
// }, router)

// import VueResource from 'vue-resource'
// Vue.use(VueResource);

// import colors from 'vuetify/lib/util/colors'

// not sure if the following works for stripe
import LoadScript from 'vue-plugin-load-script'
// import Constants from "@/constants";
Vue.use(LoadScript)

// import { StripePlugin } from '@vue-stripe/vue-stripe';
// console.log("Stripe" + process.env.VUE_APP_STRIPE_API_KEY);
// const options = {
//     //pk: "pk_test_51J8OdNAGb7MTLpmkowUVyVsfWEUSx6R98IGrcoqG6QnKOuPGBozH0RECZGhDess3WLvOIkJrD6NaIrzpDNEeOf0Q00vuPGeSiG",
//     pk: process.env.VUE_APP_STRIPE_API_KEY,
//     // stripeAccount: process.env.STRIPE_ACCOUNT,
//     // apiVersion: process.env.API_VERSION,
//     // locale: process.env.LOCALE,
// };
//
// new Promise((resolve) => {
//     let script = document.createElement('script')
//     script.onload = () => {
//         resolve();
//     }
//     // script.async = true;
//     script.defer = true;
//     script.src = 'https://js.stripe.com/v3';
//     document.head.appendChild(script);
// }).then(()=>{
//     Vue.use(StripePlugin, options);
// })

// new Promise((resolve) => {
//     let link = document.createElement('link')
//     link.onload = () => {
//         resolve();
//     }
//     // script.async = true;
//     link.rel = "stylesheet";
//     // link.href = 'https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css?display=swap';
//     link.href = '/css/materialdesignicons.min.css';
//     document.head.appendChild(link);
// }).then(()=>{
//
// })

// import VStripeElements from 'v-stripe-elements/lib'
// // and register it
// Vue.use(VStripeElements)

// https://github.com/craigh411/vue-star-rating/
// import StarRating from 'vue-star-rating'
// import AboutChef from './components/AboutChef';
// import Navigation from './components/Navigation';
// import Snackbar from './components/Snackbar';
// Vue.component('star-rating', StarRating);
// Vue.component('about-chef', AboutChef);
// Vue.component('navigation', Navigation);
// Vue.component('snackbar', Snackbar);

// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';
// import { Icon } from 'leaflet';
//
// delete Icon.Default.prototype._getIconUrl;
// Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });
//
// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);

Vue.config.productionTip = false;
Vue.prototype.$log = console.log.bind(console);

var env = process.env.VUE_APP_ENV
console.log(process.env.VUE_APP_ENV);

if (env==="prod") {
    console.log = function () {};
}

axios.defaults.withCredentials = true;

// This is a global mixin, it is applied to every vue instance
Vue.mixin({
    data: function () {
        return {
            appName: 'KOOTTO private chef',
            showDrawerDashboard:false,
            showChangePWD:false,
            showLogin:false,
            showSignup:false,
            showSignupChef:false,
            appVersion:require('../package.json').version,
            formData: {
                date: undefined,
                time: undefined,
                location: undefined,
                locationItems:undefined,
                geolocation: undefined,
                style: undefined,
                type: undefined,
                preference: undefined,
                guests:undefined
            },
            actionAfterLogin:undefined,
            datepicker: false,
            times: {
                type: Array,
                default: () => [
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30',
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
                ],
                lunchTime: () => [
                    '11:00',
                    '11:30',
                    '12:00',
                    '12:30',
                    '13:00',
                    '13:30',
                    '14:00',
                    '14:30',
                    '15:00',
                    '15:30',
                    '16:00',
                    '16:30'
                ],
                dinnerTime: () => [
                    '16:30',
                    '17:00',
                    '17:30',
                    '18:00',
                    '18:30',
                    '19:00',
                    '19:30',
                    '20:00',
                    '20:30',
                    '21:00',
                    '21:30',
                    '22:00',
                    '22:30',
                    '23:00',
                    '23:30',
                ]
            },
            guestNumber: {
                type: Array,
                default: () => [
                    0,
                    1,2,3,4,5,6,7,8,9,10,11,12
                ],
                wo0: () => [
                    1,2,3,4,5,6,7,8,9,10,11,12
                ]
            },
            languages: {
                type: Array,
                default: () => [
                    "English", "French" ,"Spanish", "Italian" ,"German", "Chinese" , "Japanese"
                ],
            },
            serviceSelected:undefined,
            chefSelected:undefined,
            // Booking
            dishesSelected:[],
            dishesCheckboxesSelected:[],
            nbKids:undefined,
            nbGuests:undefined,
            comment:undefined,
            bookingToLoad:undefined,
        }
    },
    methods:{
        ...mapMutations(["showSnackbar", "closeSnackbar"]),
        getPriceWithCommission(price){
            let commission=this.$store.state.parameters.find(value => value.name.name=="COMMISSION").value
            // this.$store.state.commission
            // not more rounding to the closes .5 anymore
            // return Math.round((parseFloat(price) * (1+commission/100) )*10/5)*5/10
            return this.roundToXDigits(parseFloat(price) * (1+commission/100),2);
            // return (Math.round((parseFloat(price) * (1+commission/100) )*100)/100);
        },
        getPriceWithCommission2(price,commission){
            // not more rounding to the closes .5 anymore
            // return Math.round((parseFloat(price) * (1+commission/100) )*10/5)*5/10
            return this.roundToXDigits(Math.round((parseFloat(price) * (1+commission/100) )*100)/100,2)
        },
        getPriceWithoutCommission2(price,commission){
            // not more rounding to the closes .5 anymore
            // return Math.round((parseFloat(price) * (1+commission/100) )*10/5)*5/10
            return this.roundToXDigits(Math.round((parseFloat(price) / (1+commission/100) )*100)/100,2)
        },
        findEventTime(name) {
            if (this.eventTime!==undefined){
                return this.eventTime.find(s=>s.name===name);
            }
        },
        roundToXDigits(value, digits) {
            if(!digits){
                digits = 2;
            }
            value = value * Math.pow(10, digits);
            value = Math.round(value);
            value = value / Math.pow(10, digits);
            return value;
        },
        round(num,dec)
            {
            num = Math.round(num+'e'+dec)
            return Number(num+'e-'+dec)
            },
        getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
            let R = 6371; // Radius of the earth in km
            let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
            let dLon = this.deg2rad(lon2-lon1);
            let a =
                Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
                Math.sin(dLon/2) * Math.sin(dLon/2)
            ;
            let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            let d = R * c; // Distance in km
            return d;
        },
         deg2rad(deg) {
            return deg * (Math.PI/180)
        },
        log(param){
            if (process.env.VUE_APP_ENV!=="prod"){
                console.log(param);
            }

        },
        openSignup(chef){
            let screenview={
                app_name: process.env.VUE_APP_ANALYTICS_NAME,
                screen_name: 'Signup Chef',
                page_title: 'Kootto - Signup Chef',
            };
            if (localStorage && localStorage.getItem("campaign")!==undefined){
                screenview.campaign=localStorage.getItem("campaign");
            }
            if (chef){
                this.$root.showSignupChef=chef;
                screenview.page_title='Kootto - Signup Chef';
                this.$gtag.screenview(screenview);

            }else{
                this.$root.showSignupChef=chef;
                screenview.page_title='Kootto - Signup';
                this.$gtag.screenview(screenview);
            }
            this.$root.showSignup=true;
        },
        checkTodoAction(){
            if (this.$root.actionAfterLogin!==undefined && this.$root.actionAfterLogin==="LOAD_BOOKING"){
                if (this.$store.state.loggedin==true){
                    this.$root.actionAfterLogin=undefined;
                    if (this.$store.state.isAdmin==true){
                        this.$router.push({path: `/admin/bookings`})
                    }else if (this.$store.state.isChef==true){
                        this.$router.push({path: `/dashboard/bookings`})
                    }else {
                        this.$router.push("/account/bookings");
                    }
                }else{
                    let error = "Please login to see your booking ";
                    this.showSnackbar({text: error, color: "error"});
                    this.$root.showLogin=true;
                }
            }
        },
        formatPackageMinMaxGuests(pack){
            if (pack !=undefined && (pack.minGuests === undefined || pack.minGuests === 0 || pack.minGuests === "") && pack.maxGuests!=undefined && pack.maxGuests!= ""){
                return "(0 - " + pack.maxGuests +" Guests)";
            }else if (pack !=undefined && pack.minGuests !== undefined && pack.minGuests !== 0 && pack.minGuests !== "" && pack.maxGuests== undefined || pack.maxGuests == ""){
                return "(" +pack.minGuests +" Guests minimum)";
            }else if (pack !=undefined && pack.minGuests !== undefined && pack.minGuests !== 0 && pack.minGuests !== "" && pack.maxGuests!= undefined && pack.maxGuests != ""){
                return "("+pack.minGuests+" - " + pack.maxGuests +" Guests)";
            }else{
                return "";
            }
        },
        formatPackageCategorySelection(cat){
            if (cat.selectionType.name == "SINGLE"){
                return "Single Choice";
            }else if (cat.selectionType.name == "MULTIPLE"){
                let text= "Multiple choice ";
                let min= "";
                let max= "";
                if (cat.minItem != undefined && cat.minItem != 0 && cat.minItem != "" ){
                    min += " from "+ cat.minItem;
                }
                // else if ( (cat.minItem == undefined || cat.minItem == 0 || cat.minItem == "" ) && (cat.maxItem !== undefined && cat.maxItem !== 0 && cat.maxItem !== "") ){
                //   text += " ( " + cat.maxItem +" maximum )"
                // }else if ( (cat.minItem != undefined && cat.minItem != 0 && cat.minItem != "" ) && (cat.maxItem !== undefined && cat.maxItem !== 0 && cat.maxItem !== "") ){
                //   text += " ( " + cat.minItem + " - " + cat.maxItem +" )"
                // }
                if (cat.maxItem !== undefined && cat.maxItem !== 0 && cat.maxItem !== "" ){
                    max += " up to " + cat.maxItem ;
                }
                if (min !="" && max !="" ){
                    text += "( " + min + " " + max + " )";
                }

                if (min !="" && max =="" ){
                    text += "( " + cat.minItem + "Minimum )";
                }

                if (min =="" && max !="" ){
                    text += "( " + max + " )";
                }

                return text;
            }else if (cat.selectionType.name == "ALL_INCLUSIVE"){
                return "All Inclusive";
            }else{
                return "";
            }
        },
        packageCategoriesSorted(pack){
            if (pack!= undefined && pack.packageCategories!==undefined){
                return pack.packageCategories.slice().sort(((a, b) => a.itemCategory.ordering - b.itemCategory.ordering));
            }else{
                return undefined;
            }
        },
        filteredDishesByCategoryForPackages(service, packageCategory) {
            if (packageCategory.limitedItems != undefined && packageCategory.limitedItems.length > 0){
                return packageCategory.limitedItems;
            }else{
                if (service !== undefined && packageCategory !== undefined & service.dishes!=undefined) {
                    return service.dishes.filter(d => d.itemCategory !== undefined && d.itemCategory.name === packageCategory.itemCategory.name)
                } else {
                    return [];
                }
            }
        },
    },
    computed:{
        isChef() {
            return this.$store.state.isChef;
        },
        isAdmin() {
            return this.$store.state.isAdmin;
        },
        eventTime() {
            // excluding morning for now
            if (this.$store.state.eventTime){
                return this.$store.state.eventTime.filter(s => s.name!=="BREAKFAST");
            }
            return undefined;
        },
        eventStatus() {
            if (this.$store.state.eventStatus){
                return this.$store.state.eventStatus.filter(s => s.name!=="RESERVED");
            }else{
                return this.$store.state.eventType;
            }
        },
        loggedin(){
            // console.log(this.$store.state.loggedin);
            return this.$store.state.loggedin;
        }
    },
    watch: {
    }
});

// const DEFAULT_TITLE = 'Kootto';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        let title=to.meta.title;
        if (to.params.city!==undefined ){
            title=title.replaceAll("Australia",to.params.city);
        }
        document.title = title /*|| DEFAULT_TITLE*/;

    });
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    // console.log(window.location.origin+ to.path);
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    const el=document.querySelectorAll('[data-vue-router-canonical]');
    // console.log(el[0]);
    let fullpath=(window.location.origin+ to.path).replaceAll("www.","");
    el[0].href=fullpath;
    const ogUrl=document.querySelectorAll('[data-vue-router-og-url]');
    ogUrl[0].content=fullpath;

    const ogImage=document.querySelectorAll('[data-vue-router-og-image]');
    ogImage[0].content=(window.location.origin).replaceAll("www.","") + require("./assets/home_1920.webp");

    const ogTitle=document.querySelectorAll('[data-vue-router-og-title]');
    ogTitle[0].content=document.title;

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    // track pages
    let screenview={
        app_name: process.env.VUE_APP_ANALYTICS_NAME,
        page_title: document.title,
        page_path: to.path,
    };
    if (localStorage && localStorage.getItem("campaign")!==undefined){
        screenview.campaign=localStorage.getItem("campaign");
    }
    // if (Vue.$gtag){
    //     Vue.$gtag.screenview(screenview);
    // }


    next();
});


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");


